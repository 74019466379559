

































import Vue from "vue";
export default Vue.extend({
  name: "Login",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleClickLogin() {
      this.login();
    },

    login() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 3000);
    },
  },
});
